import React from "react"
import styles from "./blogPost.module.css"
import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import Footer from "../components/Footer/Footer"

const BlogPost = data => {
  const {
    pageContext: {
      node: {
        field_blog_post_title_: title,
        // field_blog_post_summary_: summary,
        field_blog_post_body_: { processed: body },
        relationships: {
          field_blog_post_img_: {
            // uri: { url: image },
          },
          field_blog_post_imgpreview_: {
            // uri: { url: imagePreview },
          },
        },
      },
    },
  } = data

  return (
    <React.Fragment>
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <div className="blogPost">
          <section className={styles.blockImgMargin}>
            {/* <div className={styles.blockImgMargin__img}>
              <img src={`https://drupal.smartempo.com${image}`} alt={title} />
            </div> */}
          </section>
          <section className={styles.blockTitleTextFullWidth}>
            <div className={styles.blockTitleTextFullWidth__titleText}>
              <h1>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          </section>
          <section className={styles.blockTextImgRightMargin}>
            <div className={styles.blockTextImgRightMargin__titleText}>
              {/* <h1>{title}</h1>
              <p>{summary}</p> */}
            </div>
            <div className={styles.blockTextImgRightMargin__img}>
              {/* <img
                src={`https://drupal.smartempo.com${imagePreview}`}
                alt={title}
              /> */}
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}
export default BlogPost
